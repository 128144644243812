<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- level entitas -->
            <AFormItem
                label="Level Entitas"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="level_entitas"
                has-feedback>
                <FilterEntitasLevel
                    style="width:100%;"
                    :mode="null"
                    :disabled="form.readOnly"
                    v-model:value="form.level_entitas"/>
            </AFormItem>

            <!-- name entitas -->
            <AFormItem
                label="Nama Entitas"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="id_entitas"
                has-feedback>
                <FilterEntitasList
                    style="width:100%;"
                    :mode="null"
                    :disabled="form.readOnly"
                    v-model:level="form.level_entitas"
                    v-model:value="form.id_entitas"/>
            </AFormItem>

            <!-- radius -->
            <AFormItem
                label="Radius"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="radius"
                has-feedback>
                <AInput
                    width="100%"
                    placeholder="radius"
                    type="number"
                    :min="0"
                    :disabled="form.readOnly"
                    v-model:value="form.radius">
                    <template #suffix>
                        <ATooltip title="Meter">M</ATooltip>
                    </template>
                </AInput>
            </AFormItem>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="form.errors.any()">
            <AAlert
                v-for="(item, index) in form.errors.all()"
                type="error"
                class="mb-2"
                :key="index"
                :message="item.message"
                banner
                closable />
        </div>
        
        <!-- message errors -->
        <MessageErrors
            v-model:visible="state.errors.visible"
            :data="state.errors.data"/>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import FilterEntitasList from '@/components/filter/FilterEntitasList'
import FilterEntitasLevel from '@/components/filter/FilterEntitasLevel'
import MessageErrors from '@/components/Molecules/MessageErrors'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterEntitasList,
        FilterEntitasLevel,
        MessageErrors,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            id_entitas: null,
            level_entitas: null,
            radius: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Master Radius Toko',
            endpoint: {
                create: '/api/radius-toko',
                update: `/api/radius-toko/${props.item.id}`,
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                id_entitas: [
                    {
                        required: true,
                        message: 'Nama entitas tidak boleh kosong!',
                    },
                ],
                level_entitas: [
                    {
                        required: true,
                        message: 'Level entitas tidak boleh kosong!',
                    },
                ],
                radius: [
                    {
                        required: true,
                        message: 'Radius tidak boleh kosong!',
                    },
                ],
            },
            errors: {
                visible: false,
                data: [],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            let request = (!form.id)
                ? form.post(state.endpoint.create)
                : form.put(state.endpoint.update)

            request.then(({ data }) => {
                if (data.success) {
                    message.success(data.message)

                    handleModalCancel()
                    emit('success', data)
                } else {
                    state.errors.visible = true
                    state.errors.data = Object.values(data.message)
                        .flat() // Menggabungkan array dalam objek
                        .map(msg => ({
                            message: msg.replace(/"/g, ""), // Menghapus tanda kutip dalam angka
                            status: false, // Status false untuk semua item
                        }));
                }
            })
            .catch(({ response: { data, active } }) => {
                if (data.error) {
                    message.error(data.error)
                }
            })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.level_entitas = props.item.level_entitas
                form.id_entitas = props.item.id_entitas
                form.radius = props.item.radius

                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Master Radius Toko`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
